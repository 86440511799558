import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { GrAppsRounded } from "react-icons/gr";
import { TbMenu2 } from "react-icons/tb";
import { FiPlusSquare } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { PiPackage } from "react-icons/pi";
import { VscGraph } from "react-icons/vsc";
import { CiLogout } from "react-icons/ci";
import supabase from "../supabase";
import { IoImagesOutline } from "react-icons/io5";
import { LuPackageSearch } from "react-icons/lu";

const CategoryItems = {
  "All Products": {
    path: "/",
    icon: <GrAppsRounded size={30} color="black" />,
  },
  "Add New": {
    path: "/addnew",
    icon: <FiPlusSquare size={30} color="black" />,
  },
  "View Orders": {
    path: "/orders",
    icon: <PiPackage size={30} color="black" />,
  },
  // "View Users": {
  //   path: "/users",
  //   icon: <PiUsersThin size={30} color="black" />,
  // },
  "Add / Remove Banners": {
    path: "/banners",
    icon: <IoImagesOutline size={30} color="black" />,
  },
  "Track Orders": {
    path: "/trackawbs",
    icon: <LuPackageSearch size={30} color="black" />,
  },
  Statistics: {
    path: "/statistics",
    icon: <VscGraph size={30} color="black" />,
  },
};

export default function SideDrawer({ name, profilePic, open, setOpen }) {
  const navigate = useNavigate();

  const handleCategoryClick = (path) => {
    navigate(path);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 300 }} role="presentation" onClick={toggleDrawer(false)}>
      <TbMenu2
        size={25}
        onClick={() => {
          toggleDrawer(false);
        }}
        style={{ cursor: "pointer", marginLeft: "1em", marginTop: "2em" }}
      />
      <img
        src={profilePic}
        alt="avatar"
        style={{
          width: 60,
          borderRadius: "50%",
          margin: "auto",
          cursor: "pointer",
        }}
      />
      <h2
        style={{
          textAlign: "center",
          fontFamily: "Product Sans",
          cursor: "pointer",
        }}
      >
        Admin
      </h2>
      <List>
        {Object.entries(CategoryItems).map(([text, { path, icon }]) => (
          <ListItem
            key={text}
            disablePadding
            onClick={() => handleCategoryClick(path)}
          >
            <ListItemButton>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Product Sans",
                  color: "black",
                }}
                primary={text}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            supabase.auth.signOut();
            navigate("/");
          }}
        >
          <ListItemIcon>
            <CiLogout size={30} color="black" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontFamily: "Product Sans",
              color: "black",
            }}
            primary="Logout"
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer
        transitionDuration={500}
        open={open}
        onClose={toggleDrawer(false)}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
