import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import supabase from "../../supabase";
import moment from "moment";
import TopBar from "../../components/TopBar";
import { SessionContext } from "../../components/SessionContext";
import { IoCopyOutline } from "react-icons/io5";
import { LiaShippingFastSolid } from "react-icons/lia";
import { IoLogoWhatsapp } from "react-icons/io";
import { toast } from "react-toastify";
import { PacmanLoader, BarLoader } from "react-spinners";
import Clock from "../../components/clock";
const OrderDetail = () => {
  const { order_id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [user, setUser] = useState(null);
  const [isFetchingWaybill, setIsFetchingWaybill] = useState(false);
  const [isFetchingLabel, setIsFetchingLabel] = useState(false);

  const { session } = useContext(SessionContext);

  useEffect(() => {
    const fetchOrder = async () => {
      const { data, error } = await supabase
        .from("orders")
        .select("*")
        .eq("order_id", order_id);

      if (error) console.error("Error fetching order: ", error);
      else setOrder(data[0]);
    };

    fetchOrder();
  }, [order_id]);

  const [cod_charge, setCodCharge] = useState(0);

  useEffect(() => {
    if (order) {
      const newCodCharge = Object.values(order.items).reduce((total, item) => {
        let codPrice = 0;
        if (item.codPriceMap && item.size && item.codPriceMap[item.size]) {
          codPrice = item.codPriceMap[item.size]
            ? item.codPriceMap[item.size]
            : 0;
        } else {
          codPrice = item.cod_price ? item.cod_price : 0;
        }
        return total + codPrice;
      }, 0);
      setCodCharge(newCodCharge);
    }
  }, [order]);

  useEffect(() => {
    const fetchUser = async () => {
      if (order && order.user_id) {
        const { data, error } = await supabase
          .from("users")
          .select("*")
          .eq("id", order.user_id);

        if (error) console.error("Error fetching user: ", error);
        else setUser(data[0]);
      }
    };

    fetchUser();
  }, [order]);

  const cancelOrder = async (order_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/cancel`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
          body: JSON.stringify({
            order_id: order_id,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to cancel the order");
      }

      if (data.success) {
        toast.success("Order cancelled successfully");
        setOrder((prevOrder) => ({ ...prevOrder, order_status: "cancelled" }));
      } else {
        throw new Error(data.message || "Failed to cancel the order");
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  async function fetchShipmentLabel() {
    if (!order.waybill) return;
    if (isFetchingWaybill) return;
    if (isFetchingLabel) return;
    setIsFetchingLabel(true);
    const url = `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/shipment-label?awb=${order.waybill}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.access_token}`,
        },
      });

      const data = await response.json();

      console.log("data", data.data);

      if (response.ok && data.status === "success") {
        //window.open(data.pdf, "_blank");
        navigate("/print", { state: { packageData: data.data } });
      } else {
        toast.error(`Error: ${data.error || "Waybill Label not found"}`);
      }
    } catch (error) {
      console.error("Error fetching shipment label:", error);
      toast.error("Failed to fetch shipment label");
    }
    setIsFetchingLabel(false);
  }

  const sendShipMentRequest = async () => {
    if (isFetchingWaybill) return;
    setIsFetchingWaybill(true);
    if (order.waybill) {
      // Copy the waybill to clipboard
      navigator.clipboard.writeText(order.waybill);
      toast.success("Waybill copied to clipboard");
      setIsFetchingWaybill(false);
      return;
    }

    if (order && order.order_id && !order.waybill) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SUPABASE_URL}/functions//v1/shipment-creation?orderId=${order.order_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${session.access_token}`,
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          toast.success("Shipment created successfully");
          // Update the order state with the new waybill
          setOrder((prevOrder) => ({
            ...prevOrder,
            waybill: data.waybill,
          }));
        } else {
          toast.error(`Error: ${data.error_message}`);
        }
      } catch (error) {
        console.error("Error creating shipment:", error);
        toast.error("Error creating shipment");
      }

      setIsFetchingWaybill(false);
    }
  };

  const updateOrderStatus = async (status) => {
    if (status === "cancelled") {
      cancelOrder(order_id);
      return;
    }

    const { error } = await supabase
      .from("orders")
      .update({ order_status: status })
      .eq("order_id", order_id);

    if (error) {
      console.error("Error updating order status: ", error);
    } else {
      setOrder((prevOrder) => ({ ...prevOrder, order_status: status }));
    }
  };
  if (!order || !user)
    return (
      <div>
        <Clock />
      </div>
    );
  const printDetails = () => {
    let printWindow = window.open("", "_blank");
    printWindow.document.write(`
  <html>
  <head>
    <title>${order.order_id}</title>
    <style>
      body {
        font-family: Calibri, Arial, sans-serif;
        color: #000;
        margin: 0;
        padding: 20px;
        font-size: 25px;
        font-weight: bold;
        width: 80mm; /* Set the width to 80mm */
        word-wrap: break-word; /* Ensure text wraps */
      }
      p {
        margin: 0 0 10px 0;
      }
    </style>
  </head>
  <body>
  `);

    if (order.payment_method === "COD") {
      printWindow.document.write(`<p>COD AMOUNT</p>`);
      printWindow.document.write(`<p>Rs: ${order.amount}/- </p>`);
      printWindow.document.write(`<p>E Biller ID: 55938</p>`);
      printWindow.document.write(`<br></br>`);
    }

    printWindow.document.write("<p>To:</p>");
    printWindow.document.write(`<p>${order.address.name}</p>`);
    printWindow.document.write(`<p>${order.address.address}</p>`);
    printWindow.document.write(`<p>${order.address.city}</p>`);
    printWindow.document.write(
      `<p>${order.address.zip}, ${order.address.state}</p>`
    );
    printWindow.document.write(`<p>PH: ${order.address.phone}</p>`);
    printWindow.document.write(`<p>Wp No: ${order.address.whatsapp}</p>`);
    printWindow.document.write("<p></p>");
    printWindow.document.write(`<br></br>`);

    printWindow.document.write("<p>From: </p>");
    printWindow.document.write("<p>TAQWA FASHION STORE</p>");
    printWindow.document.write("<p>KANJIRAMATTOM, 682315</p>");
    printWindow.document.write("<p>PH: 7012556844</p>");
    printWindow.document.write("<p></p>");

    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <>
      <TopBar avatarInfo={session?.user.user_metadata} />
      <div className=" selection page h-screen overflow-y-scroll p-4 bg-gray-100 pb-40 ">
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-2xl font-bold text-blue-500">Order Detail</h1>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded flex items-center font-bold"
            onClick={sendShipMentRequest}
          >
            {isFetchingWaybill ? (
              <PacmanLoader color="white" size={10} />
            ) : order && order.waybill ? (
              <>
                {order.waybill}
                <IoCopyOutline className="ml-2" />
              </>
            ) : (
              <>
                Create Shipment
                <LiaShippingFastSolid className="ml-2" />
              </>
            )}
          </button>
        </div>

        <div className="mb-4 bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-2">Items</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {order.items.map((item) => (
              <div key={item.id} className="border p-4 rounded shadow">
                <img
                  src={item.images[0]}
                  alt={item.name}
                  className="w-full h-64 object-cover mb-4 rounded"
                />
                <h3 className="text-lg font-semibold mb-2">{item.name}</h3>
                <p className="mb-2 text-xl font-bold">
                  Price: ₹
                  {item.priceMap ? item.priceMap[item.size] : item.price}
                </p>
                <p className="mb-2 text-xl font-bold">
                  Quantity: {item.quantity}
                </p>
                {item.size !== 0 && (
                  <p className="mb-2 text-xl font-bold">Size: {item.size}</p>
                )}
                <p className="mb-2">Category: {item.category}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="mb-4 bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-2">Order Information</h2>
          <p>Order ID: {order.order_id}</p>
          {order.payment_id && <p>Payment Id: {order.payment_id}</p>}
          <p>
            Payment Method:{" "}
            <span
              style={{
                color: order.payment_method === "COD" ? "red" : "green",
                fontWeight: "bold",
              }}
            >
              {order.payment_method === "COD" ? "CASH ON DELIVERY" : "ONLINE"}
            </span>
          </p>
          <p>
            Created At:{" "}
            {moment(order.created_at).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
          <p>
            Total Amount: <b>₹{order.amount}</b>
          </p>
          <p>
            Status: <span className="text-red-600">{order.order_status}</span>
          </p>
          <select
            value={order.order_status}
            onChange={(e) => updateOrderStatus(e.target.value)}
            className="mt-2 bg-white border border-gray-300 rounded py-2 px-3 text-base shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="waiting">Waiting</option>
            <option value="shipped">Shipped</option>
            <option value="delivered">Delivered</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
        <div className="selection mb-4 bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-2">Delivery Address</h2>
          <p>{order.address.name}</p>
          <p>{order.address.phone}</p>
          <p>{order.address.address}</p>
          <p>
            {order.address.city}, {order.address.state}
            <br />
            {order.address.zip}
          </p>
          <p>
            <p>
              WP No: {order.address.whatsapp}{" "}
              <IoLogoWhatsapp
                size={30}
                color="#25d366"
                style={{
                  display: "inline",
                  marginBottom: "5px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open(
                    `https://wa.me/${order.address.whatsapp}`,
                    "_blank"
                  )
                }
              />
            </p>
          </p>

          <p>{order.address.country}</p>
        </div>

        <div className="mb-4 bg-white p-4 rounded shadow">
          <h2 className="text-xl font-semibold mb-2">User Email Information</h2>
          <p>Name: {user.raw_user_meta_data.full_name}</p>
          <p>Email: {user.email}</p>
        </div>

        <div className="flex justify-between">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => navigate("/orders")}
          >
            Back to Orders
          </button>

          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => printDetails()}
          >
            Print Taqwa Invoice
          </button>
          {order && order.waybill && (
            <button
              className="px-4 py-2 bg-[#ed4136] text-white rounded "
              onClick={() => {
                fetchShipmentLabel();
              }}
            >
              {isFetchingLabel ? (
                <BarLoader width={120} color="white" height={5} />
              ) : (
                "Delhivery Shipping Label"
              )}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default OrderDetail;
