import React, { useState, useEffect, useContext } from "react";
import { SessionContext } from "../../components/SessionContext";
import TopBar from "../../components/TopBar";
import { useNavigate } from "react-router-dom";
import supabase from "../../supabase";
import moment from "moment";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";

const Orders = () => {
  const { session } = useContext(SessionContext);
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState("waiting");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchOrders = async () => {
      let query = supabase
        .from("orders")
        .select("*")
        .order("created_at", { ascending: false });

      if (status === "waiting") {
        query = query.eq("order_status", "waiting").is("waybill", null);
      } else if (status === "ready_to_pickup") {
        query = query.eq("order_status", "waiting").not("waybill", "is", null);
      } else {
        query = query.eq("order_status", status);
      }

      const { data, error } = await query;

      if (error) {
        console.error("Error fetching orders: ", error);
      } else {
        const filteredData = data.filter(
          (order) =>
            (order.payment_method === "Razorpay" &&
              order.razorpay_payment_id) ||
            order.payment_method === "COD"
        );
        setOrders(filteredData);
      }
    };

    fetchOrders();
    const intervalId = setInterval(fetchOrders, 300000); // Call every 5 minutes
    return () => clearInterval(intervalId);
  }, [status]);

  const filteredOrders = orders.filter((order) => {
    const { name, phone, whatsapp } = order.address;
    return (
      (name && name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (order.waybill && order.waybill.includes(searchTerm.toLowerCase())) ||
      (phone && phone.includes(searchTerm)) ||
      (whatsapp && whatsapp.includes(searchTerm))
    );
  });

  return (
    <div className="page h-screen overflow-y-scroll pb-10">
      <TopBar avatarInfo={session?.user.user_metadata} />

      <div className="flex justify-center my-4">
        <button
          className={`mx-2 py-2 px-4 ${
            status === "waiting"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } rounded`}
          onClick={() => setStatus("waiting")}
        >
          Waiting
        </button>
        <button
          className={`mx-2 py-2 px-4 ${
            status === "ready_to_pickup"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } rounded`}
          onClick={() => setStatus("ready_to_pickup")}
        >
          Ready To Pickup
        </button>
        <button
          className={`mx-2 py-2 px-4 ${
            status === "shipped"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } rounded`}
          onClick={() => setStatus("shipped")}
        >
          Shipped
        </button>
        <button
          className={`mx-2 py-2 px-4 ${
            status === "delivered"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } rounded`}
          onClick={() => setStatus("delivered")}
        >
          Delivered
        </button>
        <button
          className={`mx-2 py-2 px-4 ${
            status === "cancelled"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } rounded`}
          onClick={() => setStatus("cancelled")}
        >
          Cancelled
        </button>
      </div>

      <div className="flex justify-center my-4 px-4">
        <div className="relative w-full max-w-lg">
          <input
            type="text"
            placeholder="Search by name, phone, whatsapp or waybill"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="px-4 py-2 border rounded w-full pl-10"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4">
        {filteredOrders.map((order) => (
          <div
            key={order.order_id}
            className="p-4 border rounded cursor-pointer shadow-md bg-white"
            onClick={() => navigate(`/order/${order.order_id}`)}
          >
            <div className="text-center font-semibold text-lg mb-2 text-blue-700">
              {order.items.map((item) => item.name).join(", ")}
            </div>

            <div className="text-center text-sm mb-2 text-gray-600">
              <span
                className="font-bold"
                style={{
                  color: order.payment_method === "COD" ? "red" : "green",
                  fontWeight: "bold",
                }}
              >
                {order.payment_method === "COD" ? "CASH ON DELIVERY" : "ONLINE"}
              </span>
            </div>

            <div className="text-center text-sm mb-2 text-gray-600">
              Ordered At:{" "}
              {moment(order.created_at).format("MMMM Do YYYY, h:mm:ss a")}
            </div>
            <div className="text-center text-sm mb-2 text-gray-600">
              Amount: <span className="font-bold">₹{order.amount}</span>
            </div>

            <div className="flex justify-center flex-wrap">
              {order.items.map((item) => (
                <div key={item.id} className="m-2">
                  <img
                    src={item.images[0]}
                    alt={item.name}
                    className="w-16 h-16 object-cover rounded"
                  />
                  <div className="text-center text-xs text-gray-500">
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-row items-center justify-center space-x-2">
              {order.waybill && (
                <span
                  className="text-xs text-white p-2 bg-[#ed4136] font-bold text-center rounded-md"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(order.waybill);
                    toast.success("Waybill copied to clipboard!");
                  }}
                >
                  Waybill: {order.waybill}
                </span>
              )}
              {order.waybill && (
                <span
                  className="text-xs text-white p-2 bg-blue-500 font-bold text-center rounded-md"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/trackawbs/${order.waybill}`);
                  }}
                >
                  Track Order
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Orders;
