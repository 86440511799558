import React from "react";
import { useEffect } from "react";
import Barcode from "react-barcode";
import { useLocation } from "react-router-dom";

// Add print-specific styles at the top
const printStyles = `
  @media print {
    @page {
      size: 4in 6in;
      margin: 0.1in; /* Added small margin */
    }
    body * {
      visibility: hidden;
    }
    .shipping-label,
    .shipping-label * {
      visibility: visible;
    }
    .shipping-label {
      position: absolute;
      left: 0;
      top: 0;
      width: 3.8in !important;
      padding-right: 0.2in !important; /* Added right padding */
    }
  }
`;

function ShippingLabel({ packageData }) {
  return (
    <>
      <style>{printStyles}</style>
      <div
        style={{
          width: "3.8in",
          minWidth: "3.8in",
          minHeight: "6in",
          maxWidth: "4in",
          margin: "0",
          padding: "3mm 8mm 3mm 3mm" /* Increased right padding */,
          background: "white",
          fontFamily: "Arial, Veranda, Helvetica, Avant Garde",
          pageBreakInside: "avoid",
          pageBreakAfter: "always",
        }}
        className="shipping-label scaled-wrapper"
      >
        <div className="scaled-content" style={{ transform: "scale(1)" }}>
          {" "}
          {/* Adjusted scale */}
          {/* Header Section */}
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tbody>
              <tr style={{ border: "2px solid black" }}>
                <td
                  style={{
                    borderRight: "2px solid black",
                    textAlign: "center",
                    width: "calc(3.8in / 2.1)",
                  }}
                >
                  <img
                    alt={packageData.snm}
                    src=""
                    style={{
                      width: "calc(3.8in / 2.1)",
                      fontSize: "4.7mm",
                      fontWeight: "bold",
                      display: "none",
                    }}
                    className="hide-on-error"
                  />
                  <span
                    style={{
                      width: "calc(3.8in / 2.1)",
                      fontSize: "4.7mm",
                      fontWeight: "bold",
                    }}
                    className="image-error-text"
                  >
                    {packageData.snm}
                  </span>
                </td>
                <td style={{ textAlign: "center" }}>
                  <img
                    src="https://www.delhivery.com/direct/app/email/logo.png"
                    alt="Delhivery Logo"
                    style={{ width: "calc(3.8in / 2.1)" }}
                  />
                </td>
              </tr>
              <tr style={{ border: "2px solid black" }}>
                <td align="center" colSpan="2" style={{ padding: "1mm" }}>
                  <div
                    style={{
                      maxHeight: "18mm",
                      overflow: "hidden",
                      width: "3.6in",
                    }}
                  >
                    <Barcode
                      value={packageData.wbn}
                      width={2}
                      height={35}
                      fontSize={11}
                    />
                  </div>
                </td>
              </tr>
              <tr style={{ border: "2px solid black" }}>
                <td style={{ padding: "1mm", borderRight: "2px solid black" }}>
                  {packageData.pin}
                </td>
                <td
                  align="right"
                  style={{ fontWeight: "bold", padding: "1mm" }}
                >
                  {packageData.sort_code}
                </td>
              </tr>
              <tr style={{ border: "2px solid black" }}>
                <td
                  style={{
                    borderRight: "2px solid black",
                    padding: "1mm",
                    fontSize: "3.7mm",
                    width: "50%",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Ship To:</div>
                  <div
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontSize: "initial",
                    }}
                  >
                    {packageData.name}
                  </div>
                  <div>{packageData.name}</div>
                  <div>{packageData.address}</div>
                  <div>{packageData.destination}</div>
                  <div style={{ fontWeight: "bold" }}>
                    PIN: {packageData.pin}
                  </div>
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    padding: "1mm",
                    textAlign: "center",
                  }}
                >
                  <div>{packageData.pt}</div>
                  <div>Surface</div>
                  <div style={{ margin: "4mm" }}>INR {packageData.rs}</div>
                </td>
              </tr>
              <tr style={{ border: "2px solid black" }}>
                <td
                  style={{
                    borderRight: "2px solid black",
                    padding: "1mm",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: "bold" }}>Seller:</span>{" "}
                    {packageData.snm}
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
                    {packageData.sadd}
                  </div>
                </td>
                <td style={{ padding: "1mm" }}>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Date:</span>{" "}
                    {new Date(packageData.cd).toLocaleString()}
                  </div>
                </td>
              </tr>
              <tr style={{ border: "2px solid black" }}>
                <td style={{ padding: "1mm", borderRight: "2px solid black" }}>
                  Product (Qty)
                </td>
                <td align="center" style={{ width: "15%" }}>
                  Price
                </td>
                <td align="center" style={{ width: "15%" }}>
                  Total
                </td>
              </tr>
              <tr style={{ border: "2px solid black" }}>
                <td style={{ padding: "1mm", borderRight: "2px solid black" }}>
                  {packageData.prd}
                </td>
                <td align="center">{packageData.rs}</td>
                <td align="center">{packageData.rs}</td>
              </tr>
              <tr style={{ border: "2px solid black" }}>
                <td style={{ padding: "1mm" }}>Total</td>
                <td align="center">{packageData.rs}</td>
                <td align="center">{packageData.rs}</td>
              </tr>
              <tr style={{ border: "2px solid black", maxWidth: "3.8in" }}>
                <Barcode
                  value={packageData.oid}
                  height={35}
                  width={0.5}
                  fontSize={11}
                />
              </tr>
              <tr style={{ border: "2px solid black" }}>
                <td style={{ padding: "1mm" }} colSpan={3}>
                  Return Address: {packageData.radd}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default function RenderShippingLabel() {
  const location = useLocation();
  const packageData = location.state.packageData;

  useEffect(() => {
    window.print();
  }, []);

  return <ShippingLabel packageData={packageData} />;
}
