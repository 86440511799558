import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Package, Clock, DollarSign, CreditCard, Truck } from "lucide-react";
import TopBar from "../../components/TopBar";
import supabase from "../../supabase";

const Card = ({ title, value, icon, subtitle, className = "" }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className={`bg-white p-6 rounded-xl shadow-md ${className}`}
  >
    <div className="flex justify-between items-start">
      <div>
        <p className="text-gray-500 text-sm">{title}</p>
        <h3 className="text-2xl font-bold mt-1">{value}</h3>
        {subtitle && <p className="text-sm text-gray-400 mt-1">{subtitle}</p>}
      </div>
      <div className="p-3 bg-blue-50 rounded-lg">{icon}</div>
    </div>
  </motion.div>
);

export default function Statistics() {
  const [timeRange, setTimeRange] = useState(7);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false); // Changed initial state to false

  const calculateStats = async () => {
    try {
      setLoading(true);

      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - timeRange);

      // Improved query - filter at database level
      const { data: orders, error } = await supabase
        .from("orders")
        .select("*")
        .gte("created_at", startDate.toISOString())
        .lte("created_at", endDate.toISOString())
        .neq("order_status", "cancelled"); // Filter out cancelled orders

      if (error) throw error;

      const statistics = {
        recentOrders: {
          total: 0,
          cod: 0,
          online: 0,
        },
        pendingOrders: {
          total: 0,
          cod: 0,
          online: 0,
        },
        sales: {
          total: 0,
          cod: 0,
          online: 0,
        },
        dailyStats: {},
      };

      orders.forEach((order) => {
        const orderDate = new Date(order.created_at).toLocaleDateString();
        const amount = parseFloat(order.amount) || 0;
        const isCOD = order.payment_method === "COD";

        // No need to check for cancelled orders anymore
        statistics.recentOrders.total++;
        if (isCOD) statistics.recentOrders.cod++;
        else statistics.recentOrders.online++;

        statistics.sales.total += amount;
        if (isCOD) statistics.sales.cod += amount;
        else statistics.sales.online += amount;

        // Daily stats
        if (!statistics.dailyStats[orderDate]) {
          statistics.dailyStats[orderDate] = {
            date: orderDate,
            total: 0,
            cod: 0,
            online: 0,
          };
        }
        statistics.dailyStats[orderDate].total += amount;
        if (isCOD) statistics.dailyStats[orderDate].cod += amount;
        else statistics.dailyStats[orderDate].online += amount;

        // Pending orders
        if (["waiting", "shipped"].includes(order.order_status)) {
          statistics.pendingOrders.total++;
          if (isCOD) statistics.pendingOrders.cod++;
          else statistics.pendingOrders.online++;
        }
      });

      setStats(statistics);
    } catch (error) {
      console.error("Error calculating stats:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="min-h-screen bg-gray-50  page overflow-y-scroll">
      <TopBar />

      {/* Time Range and Fetch Section */}
      <div className="mb-8 flex items-center gap-3">
        <div className="relative">
          <input
            type="number"
            min="1"
            max="365"
            value={timeRange}
            onChange={(e) => {
              const value = e.target.value === "" ? "" : Number(e.target.value);
              setTimeRange(value);
            }}
            onBlur={(e) => {
              const value = Math.max(
                1,
                Math.min(365, Number(e.target.value) || 1)
              );
              setTimeRange(value);
            }}
            className="w-24 px-4 py-2 border border-gray-300 rounded-lg shadow-sm
           text-center font-medium text-gray-700
           hover:border-blue-500 focus:outline-none focus:ring-2 
           focus:ring-blue-500 focus:border-blue-500 transition-all"
          />
        </div>
        <span className="text-gray-600 font-medium">days ago</span>

        <button
          onClick={calculateStats}
          disabled={loading}
          className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-lg 
                   hover:bg-blue-600 focus:outline-none focus:ring-2 
                   focus:ring-blue-500 focus:ring-offset-2 transition-all
                   disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? "Fetching..." : "Fetch Stats"}
        </button>
      </div>

      {/* Show message when no stats are loaded */}
      {!stats && !loading && (
        <div className="text-center text-gray-500 py-8">
          Select a time range and click "Fetch Stats" to view statistics
        </div>
      )}

      {/* Show loading message */}
      {loading && (
        <div className="text-center text-gray-500 py-8">
          Loading statistics...
        </div>
      )}

      {/* Only show stats content when data is available */}
      {stats && !loading && (
        <>
          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            <Card
              title={`Orders (Last ${timeRange} days)`}
              value={stats.recentOrders.total}
              subtitle={`COD: ${stats.recentOrders.cod} | Online: ${stats.recentOrders.online}`}
              icon={<Package className="text-blue-500" />}
            />
            <Card
              title="Pending Orders"
              value={stats.pendingOrders.total}
              subtitle={`COD: ${stats.pendingOrders.cod} | Online: ${stats.pendingOrders.online}`}
              icon={<Clock className="text-yellow-500" />}
            />
            <Card
              title={`Sales (Last ${timeRange} days)`}
              value={`₹${stats.sales.total.toLocaleString()}`}
              subtitle={`COD: ₹${stats.sales.cod.toLocaleString()} | Online: ₹${stats.sales.online.toLocaleString()}`}
              icon={<DollarSign className="text-green-500" />}
            />
          </div>

          {/* Sales Chart */}
          <div className="bg-white p-6 rounded-xl shadow-md">
            <h3 className="text-lg font-semibold mb-4">
              Daily Sales Breakdown
            </h3>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={Object.values(stats.dailyStats)
                  .map((day) => ({
                    ...day,
                    dateObj: new Date(day.date.split("/").reverse().join("-")),
                  }))
                  .sort((a, b) => a.dateObj - b.dateObj)
                  .map(({ dateObj, ...rest }) => rest)}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="cod" name="COD Sales" fill="#FFA500" />
                <Bar dataKey="online" name="Online Sales" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  );
}
