import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import {
  FiPackage,
  FiMapPin,
  FiSearch,
  FiBox,
  FiNavigation,
  FiCalendar,
  FiActivity,
} from "react-icons/fi";
import { FiUser, FiMap, FiGlobe, FiHash, FiDollarSign } from "react-icons/fi";
import TopBar from "../../components/TopBar";
import { SessionContext } from "../../components/SessionContext";
import { useParams } from "react-router-dom";

export default function WayBillStatus() {
  const [waybill, setWaybill] = useState("");
  const [loading, setLoading] = useState(false);
  const [shipmentData, setShipmentData] = useState(null);
  const [error, setError] = useState(null);
  //get user sessioon using supabase

  const { session } = useContext(SessionContext);

  const { awb } = useParams();

  useEffect(() => {
    if (awb) {
      setWaybill(awb);
    }
  }, [awb]);

  useEffect(() => {
    if (waybill) {
      handleSubmit();
    }
  }, [waybill]);

  const progressWidth = shipmentData?.Scans
    ? (shipmentData.Scans.length / 5) * 100
    : 0;

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/shipment-track?awb=${waybill}`, //change the url later,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
        }
      );
      const data = await response.json();

      if (response.ok) {
        setShipmentData(data);
      } else {
        setError(data.error);
      }
    } catch (err) {
      setError("Failed to fetch tracking information");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=" page bg-gray-50 min-h-screen overflow-y-scroll">
      <TopBar />
      <div className="max-w-7xl mx-auto p-4">
        {/* Search Section */}
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="bg-gradient-to-r from-blue-500 to-blue-600 rounded-2xl p-8 mb-8 shadow-lg"
        >
          <h1 className="text-3xl font-bold text-white mb-6">
            Track Your Shipment
          </h1>
          <form onSubmit={handleSubmit} className="relative">
            <FiSearch
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
            <input
              type="text"
              value={waybill}
              onChange={(e) => setWaybill(e.target.value)}
              placeholder="Enter your tracking number"
              className="w-full pl-12 pr-32 py-4 rounded-xl focus:ring-2 focus:ring-blue-300 outline-none"
            />
            <button
              type="submit"
              disabled={loading}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              {loading ? (
                <span className="flex items-center">
                  <FiActivity className="animate-spin mr-2" /> Tracking...
                </span>
              ) : (
                "Track"
              )}
            </button>
          </form>
        </motion.div>

        {error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg mb-8"
          >
            <div className="flex items-center">
              <FiActivity className="text-red-500 mr-2" />
              <p className="text-red-700">{error}</p>
            </div>
          </motion.div>
        )}

        {shipmentData && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="grid grid-cols-1 lg:grid-cols-3 gap-8"
          >
            {/* Shipment Card */}
            <motion.div
              initial={{ x: -20 }}
              animate={{ x: 0 }}
              className="lg:col-span-1 space-y-6"
            >
              {/* Basic Details */}
              <div className="bg-white rounded-2xl p-6 shadow-lg">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-bold">Shipment Details</h2>
                  <FiBox className="text-blue-500" size={24} />
                </div>
                <div className="space-y-4">
                  <DetailItem
                    icon={<FiPackage />}
                    label="AWB"
                    value={shipmentData.AWB}
                  />
                  <DetailItem
                    icon={<FiActivity />}
                    label="Status"
                    value={shipmentData.Status}
                  />
                  <DetailItem
                    icon={<FiNavigation />}
                    label="Origin"
                    value={shipmentData.Origin}
                  />
                  <DetailItem
                    icon={<FiMapPin />}
                    label="Destination"
                    value={shipmentData.Destination}
                  />
                  <DetailItem
                    icon={<FiCalendar />}
                    label="Pickup Date"
                    value={shipmentData.PickUpDate}
                  />
                  <DetailItem
                    icon={<FiCalendar />}
                    label="Delivery Date"
                    value={shipmentData.DeliveryDate}
                  />
                  <DetailItem
                    icon={<FiCalendar />}
                    label="Promised Delivery"
                    value={shipmentData.PromisedDeliveryDate}
                  />
                  <DetailItem
                    icon={<FiBox />}
                    label="Quantity"
                    value={shipmentData.Quantity}
                  />
                  <DetailItem
                    icon={<FiActivity />}
                    label="Reference No"
                    value={shipmentData.ReferenceNo}
                  />
                </div>
              </div>

              {/* Consignee Details */}
              <div className="bg-white rounded-2xl p-6 shadow-lg">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-bold">Consignee Details</h2>
                  <FiUser className="text-blue-500" size={24} />
                </div>
                <div className="space-y-4">
                  <DetailItem
                    icon={<FiUser />}
                    label="Name"
                    value={shipmentData.Consignee.Name}
                  />
                  <DetailItem
                    icon={<FiMapPin />}
                    label="City"
                    value={shipmentData.Consignee.City}
                  />
                  <DetailItem
                    icon={<FiMap />}
                    label="State"
                    value={shipmentData.Consignee.State}
                  />
                  <DetailItem
                    icon={<FiGlobe />}
                    label="Country"
                    value={shipmentData.Consignee.Country}
                  />
                  <DetailItem
                    icon={<FiHash />}
                    label="Pin Code"
                    value={shipmentData.Consignee.PinCode}
                  />
                </div>
              </div>

              {/* Payment Details */}
              <div className="bg-white rounded-2xl p-6 shadow-lg">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-bold">Payment Details</h2>
                  <FiDollarSign className="text-blue-500" size={24} />
                </div>
                <div className="space-y-4">
                  <DetailItem
                    icon={<FiDollarSign />}
                    label="Invoice Amount"
                    value={`₹${shipmentData.InvoiceAmount}`}
                  />
                  <DetailItem
                    icon={<FiDollarSign />}
                    label="COD Amount"
                    value={`₹${shipmentData.CODAmount}`}
                  />
                </div>
              </div>
            </motion.div>
            {/* Timeline Card */}
            <motion.div
              initial={{ x: 20 }}
              animate={{ x: 0 }}
              className="lg:col-span-2 bg-white rounded-2xl p-6 shadow-lg"
            >
              <h2 className="text-xl font-bold mb-6">Tracking Timeline</h2>
              <div className="space-y-6">
                {[...shipmentData.Scans].reverse().map((scan, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="relative flex items-start group"
                  >
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-100 group-hover:bg-blue-500 transition-colors">
                      <FiMapPin className="text-blue-500 group-hover:text-white transition-colors" />
                    </div>
                    <motion.div
                      className="ml-4 bg-gray-50 p-4 rounded-lg flex-grow"
                      whileHover={{ scale: 1.02 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <p className="font-semibold text-gray-800">{scan.Scan}</p>
                      <div className="flex items-center text-sm text-gray-500 mt-2">
                        <FiMapPin className="mr-2" />
                        <span>{scan.ScannedLocation}</span>
                      </div>
                      <div className="flex items-center text-sm text-gray-500 mt-1">
                        <FiCalendar className="mr-2" />
                        <span>{scan.ScanDateTime}</span>
                      </div>
                    </motion.div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </motion.div>
        )}
      </div>
    </div>
  );
}

const DetailItem = ({ icon, label, value }) => (
  <motion.div
    className="flex items-center p-3 rounded-lg hover:bg-gray-50"
    whileHover={{ scale: 1.02 }}
  >
    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 mr-3">
      {icon}
    </div>
    <div>
      <p className="text-sm text-gray-500">{label}</p>
      <p className="font-semibold">{value}</p>
    </div>
  </motion.div>
);
